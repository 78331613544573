'use client';
import { useAnimate } from 'framer-motion';
import { useEffect } from 'react';

const Separator = ({ index }: { index: number }) => {
  const [scope, animate] = useAnimate();

  useEffect(() => {
    const animation = async () => {
      animate([
        ['#vertical', { scaleY: 0 }, { type: 'tween', duration: 0.16 }],
        [
          '#horizontal',
          {
            scaleX: 0,
            scaleY: 1,
            x: '-50%',
          },
          { type: 'tween', duration: 0.16 },
        ],
        [
          '#horizontal',
          {
            scaleX: 1,
            scaleY: 1,
            x: '-50%',
          },
          { type: 'tween', duration: 0.16 },
        ],
        ['#vertical', { scaleY: 1 }, { type: 'tween', duration: 0.16 }],
      ]);
    };
    animation();
  }, [index]);

  return (
    <div ref={scope} className='relative'>
      <div
        id='vertical'
        className={
          'grow-0 shrink-0 basis-1 h-full w-1 bg-primary-content origin-top will-change-transform '
        }></div>
      <div
        id='horizontal'
        className='absolute top-0 left-0 w-[200px] h-1 bg-primary-content origin-top-left -translate-x-1/2 will-change-transform '
      />
    </div>
  );
};

export default Separator;
