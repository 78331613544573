'use client';
import { MutableRefObject, Suspense, useRef, useState } from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

import dynamic from 'next/dynamic';
import { useLoader } from '@react-three/fiber';

import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader.js';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';
const Environment = dynamic(() =>
  import('@react-three/drei/core/Environment').then((mod) => mod.Environment)
);
const OrbitControls = dynamic(() =>
  import('@react-three/drei/core/OrbitControls').then(
    (mod) => mod.OrbitControls
  )
);

const Canvas = dynamic(() =>
  import('@react-three/fiber').then((mod) => mod.Canvas)
);
const RadarSkill = dynamic(() => import('./RadarSkill'), {
  ssr: false,
  loading: () => <LoadingSpinner />,
});

const skills = {
  backend: {
    description: '',
    projects: [],
    model: '/backend.obj',
    material: '/backend.mtl',
  },
  frontend: {
    description: '',
    projects: [],
    model: '/frontend.obj',
    material: '/frontend.mtl',
  },
  ai: {
    description: '',
    projects: [],
    model: '/ai.obj',
    material: '/ai.mtl',
  },
};

const CharacterModel = ({
  materialUrl,
  modelUrl,
  isHide,
}: {
  materialUrl: string;
  modelUrl: string;
  isHide: boolean;
}) => {
  const materials = useLoader(MTLLoader, materialUrl);

  const characterModel = useLoader(OBJLoader, modelUrl, (loader) => {
    materials?.preload();
    loader?.setMaterials(materials);
  });
  characterModel.visible = !isHide;
  return <primitive object={characterModel} />;
};

const Skills = ({ categories }: { categories: any[] }) => {
  const [currentSkill, setCurrentSkill] = useState(0);
  const targetRef = useRef<HTMLElement>(null) as MutableRefObject<HTMLElement>;
  const { scrollYProgress } = useScroll({
    target: targetRef,
  });
  const rotateYProgress = useTransform(
    scrollYProgress,
    [0, 1],
    [Math.PI * 2, Math.PI * 4]
  );
  const [rotateY, setRotateY] = useState(0);
  const skillLists = Object.keys(skills);
  const skillDescriptions = Object.values(skills);
  rotateYProgress.on('change', (value) => {
    setRotateY(value);
  });
  scrollYProgress.on('change', (value) => {
    if (value < 1 / 3) {
      setCurrentSkill(0);
    } else if (value >= 1 / 3 && value < 2 / 3) {
      setCurrentSkill(1);
    } else {
      setCurrentSkill(2);
    }
  });

  const skillsMenu = (
    <ul className='flex flex-col gap-8 w-fit items-end'>
      {skillLists.map((skill, skillIndex) => {
        const id = `${skillIndex}`;
        return (
          <motion.li
            key={skillIndex}
            transition={{
              type: 'spring',
              duration: 0.25,
            }}>
            <input
              className='peer hidden'
              type='radio'
              name='skill'
              id={id}
              value={skillIndex}
              checked={skillIndex === currentSkill}
              readOnly
            />
            <motion.label
              transition={{
                type: 'spring',
                duration: 0.25,
              }}
              htmlFor={id}
              className={
                'text-primary-content-100 dark:text-secondary-content-100 text-xl md:text-3xl w-fit relative uppercase font-semibold text-right' +
                ' after:origin-right after:bg-primary-content after:dark:bg-secondary-content after:absolute after:-bottom-[4px] after:right-0' +
                ' after:duration-500 after:content-[""] after:block after:w-0 after:h-[3.5px]' +
                ' peer-checked:text-primary-content peer-checked:dark:text-secondary-content peer-checked:after:w-full peer-checked:font-bold peer-checked:text-2xl peer-checked:md:text-4xl'
              }>
              {skill}
            </motion.label>
          </motion.li>
        );
      })}
    </ul>
  );

  const models = (
    <Canvas
      eventPrefix='page'
      orthographic={true}
      onCreated={({ camera }) => {
        camera.rotation.y = rotateY;
      }}
      camera={{
        left: -1,
        right: 1,
        top: 1,
        bottom: -1,
        zoom: 25,
        scale: [1, 1, 1],
        castShadow: true,
        rotation: [0, rotateY, 0],
      }}
      className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 -z-10'
      style={{
        width: '800px',
        height: '800px',
      }}>
      <OrbitControls
        enableZoom={false}
        maxPolarAngle={Math.PI / 2}
        minPolarAngle={Math.PI / 2}
        enablePan={false}
        enableRotate={true}
      />
      <ambientLight intensity={1} />
      <pointLight position={[1, -1, 1]} intensity={10} />
      <group rotation-z={0} rotation-y={rotateY} rotation-x={0}>
        {skillDescriptions.map((info, key) => {
          return (
            <CharacterModel
              key={key}
              isHide={key !== currentSkill}
              materialUrl={info.material}
              modelUrl={info.model}
            />
          );
        })}
      </group>
      <Environment preset='sunset' />
    </Canvas>
  );
  return (
    <section
      className='w-full bg-transparent overflow-clip relative h-[300vh]'
      ref={targetRef}>
      <div className='sticky top-0 -z-10 overflow-hidden h-screen'>
        <Suspense
          fallback={
            <div className='w-full h-full flex justify-center items-center'>
              <LoadingSpinner />
            </div>
          }>
          {models}
        </Suspense>
        <div className='w-full px-8 flex flex-row items-center justify-end absolute top-1/2 left-0 -translate-y-1/2 -z-0'>
          {skillsMenu}
        </div>

        <RadarSkill
          className={
            'absolute top-1/2 left-8 -translate-y-1/2 -z-0 invisible md:visible'
          }
          skill={categories[currentSkill].skillChart}
        />
      </div>
    </section>
  );
};

export default Skills;
